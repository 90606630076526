@use '@angular/material' as mat;

html {
    height: 100%;
}

body {
    height: 100%;
    @include mat.icon-button-density(-3);
    @include mat.checkbox-density(-3);
    @include mat.radio-density(-3);
}

/* You can add global styles to this file, and also import other style files */
//@import "@angular/material/prebuilt-themes/indigo-pink.css";
@import "themes";

// $fa-font-path: "node_modules/@fortawesome/fontawesome-free/webfonts";
@import '../node_modules/@fortawesome/fontawesome-free/scss/fontawesome';
@import '../node_modules/@fortawesome/fontawesome-free/scss/regular';
@import '../node_modules/@fortawesome/fontawesome-free/scss/solid';
@import '../node_modules/@fortawesome/fontawesome-free/scss/brands';
@import '../node_modules/@fortawesome/fontawesome-free/scss/shims';
@import '../node_modules/@fortawesome/fontawesome-free/scss/v4-shims';

@font-face {
    font-family: poppins;
    src: url(assets/fonts/Poppins-Regular.ttf);
}

mat-icon {
    box-sizing: unset !important;
    svg {
        vertical-align: unset;
    }
}

tr {
    box-sizing: unset !important;
}

@media only screen and (min-width: 1200px) {
    html, body {
        height: 100%;
    }
}

body {
    margin: 0;
}

.pre-tooltip {
    white-space: pre-line;
}

.buttons {
    margin-top: 25px;
}

ngx-material-timepicker-container {
    z-index: 1000;
    position: absolute;
}

.hidden {
    visibility: hidden;
}

.extra-long-select-panel {
    max-height: 90vh !important;
}

.graph-date-indicator {
    background: linear-gradient(90deg, white 77%, transparent);
    color: #000A;
    padding: 3px 28px 0;
    z-index: 999;
    height: 20px;
    position: absolute;
    top: 300px;
    font-size: 14px;
    line-height: normal;
}

// Fix for ngx-timepicker
ngx-timepicker-field {
    height: 45px;
    min-width: 244px;

    .ngx-timepicker {
        background-color: rgba(0,0,0,.04);
    }

    .ngx-timepicker--disabled {
        background-color: #e0e0e0 !important;
        opacity: 0.5;
    }

    .ngx-timepicker-control > input, span:not(.ngx-timepicker-control__arrow) {
        transform: translate(0, 8px);
    }
    .ngx-timepicker {
        align-items: start !important;
    }

    input {
        background-color: inherit;
    }

    ngx-material-timepicker-toggle {
        position: relative;
        left: 60px;
    }
}


.mat-mdc-menu-content > button > i {
    display: inline-block;
    margin-right: 16px;
    vertical-align: middle;
    height: 24px;
    width: 24px;
}


mat-form-field.transparent {
    /* Angular 15 adds grey background on mat form components */
    /* below removes that background color */
    /* Manipulate initial background color*/
    .mdc-text-field--filled:not(.mdc-text-field--disabled) {
        background-color: transparent !important;
    }
    /*Manipulate background color on hover and focus*/
    .mat-mdc-form-field-focus-overlay {
        background-color: transparent !important;
    }
}

mat-paginator {
    .mdc-notched-outline__trailing,
    .mdc-notched-outline__notch,
    .mdc-notched-outline__leading {
        border: none !important;
    }
}

.scale-0x5 {
    transform: scale(0.5);
}

.scale-0x8 {
    transform: scale(0.8);
}

.scale-0x9 {
    transform: scale(0.9);
}

.scale-1x2 {
    transform: scale(1.2);
}

.scale-1x5 {
    transform: scale(1.5);
}

.scale-1x8 {
    transform: scale(1.8);
}

.scale-2x {
    transform: scale(2);
}

.scale-2x5 {
    transform: scale(2.5);
}

mat-card {
    padding: 16px
}

.mdc-list-item__primary-text{
    flex-grow: 1;
}

.mat-mdc-optgroup-label {
    width: 100%;
}

.mat-mdc-table {
    th {
        color: gray;
        font-size: small;
    }
    td {
        font-size: 1.1em;
    }
}

// If someone figures out, how to add this to the theme in a working way, it would be cool 😵‍💫
button.mat-accent {
    .mdc-button__label {
        color: white;
    }
}

.dygraph-axis-label-y {
    overflow: visible;
    span {
        display: inline-block;
        transform: translate(0, -7px);
    }
}

.clickable {
    cursor: pointer;
}
